import $ from 'jquery'
import MicroModal from 'micromodal/dist/micromodal';

class MyMicroModal {
  show(target) {
    MicroModal.show(target);
  }

  close() {
    MicroModal.close();
  }
}

window._MicroModal = new MyMicroModal()
